export default {
  address: '台北市安區瑞安街244巷16號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d903.7980696290949!2d121.5384704!3d25.027548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442aa2a257bab43%3A0xf5c13b0583af5798!2z55Ge5a6J6Ieq5Zyo!5e0!3m2!1szh-TW!2stw!4v1597130653568!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/oPw7RoKQvXFTHdFW8',
  phone: '02-2707-1288',
  fbLink: 'https://www.facebook.com/forevercollect/',
  fbMessage: 'https://m.me/forevercollect',
  caseName: '瑞安自在',
  indigatorLength: 10,

  houseInfos: [
    ['全案執行', '首鋼機構'],
    ['投資興建', '首鋼開發股份有限公司'],
    ['營造公司', '首鋼營造股份有限公司'],
    ['建築設計', '邱垂睿建築師事務所'],
    ['藝術規劃', '英國藝術家貝馬丁Martyn Barratt'],
    ['結構設計', '宗誠結構林文宗技師'],
    ['公設設計', '禾鼎設計楊豪琳設計師'],
    ['園藝設計', '花茂造景胡憲民設計師'],
    ['樓層規劃', '地上12層，地下3層'],
    ['格局規劃', '四房(63.88坪、66.88坪) 、90坪'],
    ['基地地址', '台北市大安區瑞安街244巷16.18號'],
  ],

  gtmCode: ['NP9Q357'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
